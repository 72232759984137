const DummyFunc = () => {
  return null;
};
export default DummyFunc;

const createActionData = (action, loggedInUser) => {
  return {
    action_at: new Date(),
    action: action,
    actor: {
      name: loggedInUser?.name,
      gautra: loggedInUser?.gautra,
      subcaste: loggedInUser?.subcaste,
      village: loggedInUser?.village,
      pic_url: loggedInUser?.pic_url || null,
    },
  };
};

export { createActionData };
